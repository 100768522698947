<template>
<section class="text-and-image"
         :class="[className,data.custom_class]">
	<div v-if="data.gold_square_left"
	     :data-aos="data.gold_square_animation.name"
	     :data-aos-duration="data.gold_square_animation.duration*100"
	     class="gold-square-left"></div>
	<div class="custom-container">
		<div class="text-and-image__container columns is-vcentered">
			<div class="column image-wrapper"
			     :data-aos="data.image_animation.name"
			     :data-aos-duration="data.image_animation.duration*100"
			     v-if="data.image.image"
			     :style="{order:data.image.responsive.fullhd_grid.order}">
				<div class="content">
					<figure class="image">
						<img :src="data.image.image"
						     alt />
					</figure>
				</div>
			</div>
			<div :data-aos="data.content_animation.name"
			     :data-aos-duration="data.content_animation.duration*100"
			     class="text-and-image__text-box column content-wrapper"
			     v-if="data.content.content"
			     :class="printClass(data,'content')"
			     :style="{order:data.content.responsive.fullhd_grid.order}">
				<div v-html="data.content.content">

				</div>
				<router-link class="main-link"
				             v-if="data.link.href && data.link.text"
				             :to="data.link.href">{{data.link.text}}</router-link>
			</div>

		</div>
	</div>
	<div class="square-left-align-box"></div>
</section>
</template>

<script>
export default {
	name: "TextAndImage",
	data() {
		return {};
	}
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import '@/styles/framework/breakpoints.scss';

.mobile-row-display {
    .text-and-image__container {
        display: flex;
        flex-direction: row;
        @include breakpoint-max("mobile") {
            flex-direction: column;
        }
    }
}
.reverse {
    .text-and-image__container {
        @include touch {
            flex-direction: column-reverse;
        }
    }
}
.padding-top-10 {
    padding-top: 10px !important;
}
.padding-top-0 {
    padding-top: 0 !important;
}
.padding-bottom-txt-m-30 {
    .text-and-image__text-box {
        @include touch {
            padding-bottom: 30px;
        }
    }
}
.padding-bottom-m-0 {
    @include touch {
        padding-bottom: 0 !important;
    }
    .content {
        figure {
            padding-bottom: 0;
            margin-bottom: 0;
            img {
                object-fit: cover;
            }
        }
    }
}
.margin-top-minus-55 {
    margin-top: -55px;
    @include breakpoint-max("large") {
        margin-top: 0;
    }
}
.gold-square-left {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 650px;
    background-color: $beigeBg;
    z-index: 1;
    @include touch {
        display: none;
    }
}
.image-wrapper {
    @include touch {
        width: 100%;
    }
    .content {
        display: flex;
    }
    figure {
        @include touch {
            width: 100%;
            display: flex;
            margin-bottom: 30px;
        }
    }
}
.text-and-image {
    padding: 100px 0;
    position: relative;
    @include touch {
        padding: 40px 0;
    }
    &__container {
        z-index: 1;
        @include touch {
            display: flex;
            flex-direction: column;
        }
    }
    &__text-box {
        padding: 0 50px;
        @include touch {
            padding: 0 38px;
        }
        /deep/ h2 {
            color: $dark;
            max-width: 400px;
            font-family: 'BlackerProDis-Rg';
            text-transform: none;
            font-size: 3.4rem;
            line-height: 1.3;
            margin-bottom: 20px;
        }
        /deep/ p {
            color: $gray;
            font-size: 1.8rem;
        }
    }
    figure {
        z-index: 3;
        img {
            z-index: 3;
        }
    }
}
</style>

<template>
<section class="intro"
         :class="data.custom_class">
	<div class="intro__container">
		<div class="intro__text-container"
		     :data-aos="data.content_animation.name"
		     :data-aos-duration="data.content_animation.duration*100"
		     :style="{'order': data.order_content}">

			<div class="intro__text">
				<div v-html="data.content"></div>
				<router-link class="main-link "
				             v-if="data.link_href && data.link_text"
				             :to="data.link_href">{{data.link_text}}</router-link>
			</div>

		</div>
		<div class="intro__image"
		     :data-aos="data.image_animation.name"
		     :data-aos-duration="data.image_animation.duration*100"
		     :style="{'order': data.order_image, 'background-image': 'url(' + data.image.url + ')' }"></div>
	</div>
</section>
</template>

<script>
export default {
	name: "Intro",
	data() {
		return {};
	}
};
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.sass';
@import '@/styles/framework/breakpoints.scss';

.text-padding-right {
    .intro__text {
        padding-right: 60px;
        @include touch {
            padding-right: 30px;
        }
    }
}
.intro {
    padding: 0;
    margin: 0;
    width: 100%;
    &__container {
        display: flex;
        min-height: 400px;
        align-items: center;
        @include breakpoint-max("xs-tablet") {
            flex-direction: column;
        }
    }
    &__text-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
    }
    &__image,
    &__text {
        flex: 1;
    }
    &__text {
        padding: 0 50px;
        max-width: 700px;
        font-family: 'BlackerProDis-LtIt';
        @include breakpoint-max("xs-tablet") {
            padding: 50px 30px;
            height: 400px;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        @include mobile {
            height: 450px;
        }
        /deep/ strong {
            color: $gold;
        }
        /deep/ p {
            font-size: 3.4rem;
            line-height: 1.35;
            @include breakpoint-max("xs-tablet") {
                font-size: 2.4rem;
            }
        }
        /deep/ .main-link {
            margin-top: 30px;
        }
    }
    &__image {
        min-height: calc(100vh - 83px);
        background-position: center right;
        background-repeat: no-repeat;
        background-size: cover;
        @include breakpoint-max("tablet"){
            background-position: left;
        }
        @include breakpoint-max("xs-tablet"){
           height: 500px;
           min-height: 500px;
           width: 100%;
           background-position: bottom;
        }
        @include breakpoint-max("mobile"){
            background-position: center;
        }
    }
}
</style>

<template>
    <div>
        <component
            v-for="(mod,key) in block"
            :key="key"
            :id-number="key+1"
            :is="getComponentObject(mod.acf_fc_layout)"
            :data="mod"
            :class-name="getClassName(mod.acf_fc_layout)"
            :print-class="printClass"
        ></component>
    </div>
</template>

<script>
export default {
    computed: {
        block() {
            return this.$store.getters.getBlock(this.data.block)
        }
    },
    methods: {
        pascalize(value){
            let trimmed = value.trim();
            return trimmed.substr(0, 1).toUpperCase() + trimmed.substr(1).replace(/[\W_]$/, '').replace(/[\W_]([a-zA-Z0-9])/g, (_, x) => x.toUpperCase());
        },
        getClassName(name){
            return 'flex-'+name.replace(/_/g,'-')
        },
        getComponentObject(name){
            return this.$options.components['Flex'+this.pascalize(name)]
        },
    }
}
</script>

<style lang="sass">
</style>

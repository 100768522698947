<template>
<section class="book"
         :class="data.custom_class">
	<div class="book__gray-container"
	     :data-aos="data.book_gray_animation.name"
	     :data-aos-duration="data.book_gray_animation.duration*100">
		<h2>{{ data.title }}</h2>
		<div class="book__intro"
		     :data-aos="data.intro_animation.name"
		     :data-aos-duration="data.intro_animation.duration*100"
		     v-html="data.intro"></div>
		<figure>
			<img :data-aos="data.image_animation.name"
			     :data-aos-duration="data.image_animation.duration*100"
			     :src="data.image.url"
			     :alt="data.image.alt">
		</figure>
		<form @submit.prevent="onSubmit"
		      :data-aos="data.form_animation.name"
		      :data-aos-duration="data.form_animation.duration*100">
			<div class="field">
				<div class="control">
					<input v-if="lang == 'en'"
					       class="book__text"
					       type="text"
					       placeholder="First Name"
					       name="name"
					       v-model="name"
					       required>
					<input v-if="lang == 'de'"
					       class="book__text"
					       type="text"
					       placeholder="Vorname, Name"
					       name="name"
					       v-model="name"
					       required>
				</div>
			</div>
			<div class="field">
				<div class="control">
					<input v-if="lang == 'en'"
					       class="book__text"
					       type="text"
					       placeholder="E-mail address"
					       name="email"
					       v-model="email"
					       required>
					<input v-if="lang == 'de'"
					       class="book__text"
					       type="text"
					       placeholder="E-Mail"
					       name="email"
					       v-model="email"
					       required>
				</div>
			</div>
			<div class="field">
				<div class="control">
					<input v-if="lang == 'en'"
					       class="book__text"
					       type="text"
					       placeholder="Phone number"
					       name="phone"
					       v-model="phone"
					       required>
					<input v-if="lang == 'de'"
					       class="book__text"
					       type="text"
					       placeholder="Telefonnummer"
					       name="phone"
					       v-model="phone"
					       required>
				</div>
			</div>
			<div class="field">
				<div class="book__textarea-container control">
					<textarea v-if="lang == 'en'"
					          class="book__textarea"
					          name="message"
					          rows="8"
					          cols="80"
					          v-model="message"
					          placeholder="Message"></textarea>
					<textarea v-if="lang == 'de'"
					          class="book__textarea"
					          name="message"
					          rows="8"
					          cols="80"
					          v-model="message"
					          placeholder="Nachricht"></textarea>
				</div>
			</div>
			<div class="field"
			     v-if="lang == 'de'">
				<div class="control">
					Diese Seite ist über reCAPTCHA gesichert und es gelten die <a href="https://policies.google.com/privacy">Google Richtlinien</a> und <a href="https://policies.google.com/terms">Nutzungsbedingungen.</a><br>
					<label class="checkbox">
						<input class="book__checkbox"
						       type="checkbox"
						       name="agreement-1"
						       id="agreement-1"
						       required>
						<span>Ich stimme den Nutzungsbedingungen und Konditionen zu</span>
					</label>
				</div>
			</div>
			<div class="field"
			     v-if="lang == 'en'">
				<div class="control">
					This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.<br />
					<label class="checkbox">
						<input class="book__checkbox"
						       type="checkbox"
						       name="agreement-1"
						       id="agreement-1"
						       required>
						<span>I agree to the terms and conditions</span>
					</label>
				</div>
			</div>
			<vue-recaptcha ref="recaptcha"
			               @verify="onVerify"
			               @expired="onExpired"
			               size="invisible"
			               :sitekey="sitekey">
			</vue-recaptcha>
			<div class="field">
				<div class="control">
					<input v-if="lang == 'en'"
					       class="book__send"
					       type="submit"
					       name="submit"
					       value="Book a Session">
					<input v-if="lang == 'de'"
					       class="book__send"
					       type="submit"
					       name="submit"
					       value="Eine Sitzung buchen">
				</div>
			</div>
			<transition name="fade"
			            mode="out-in">
				<article class="message"
				         :class="{'is-success':infoBox.type == 1,'is-danger':infoBox.type == 2}"
				         v-if="infoBox.type != 0"
				         @click="infoBox.type = 0">
					<div class="message-header">
						<p>{{ infoBox.type == 1 ? 'Sucess' : 'Error' }}</p>
						<button class="delete"
						        aria-label="delete"></button>
					</div>
					<div class="message-body">
						{{ infoBox.content }}
					</div>
				</article>
			</transition>
		</form>
	</div>
	<!-- <div v-if="data.blue_dark_container"
	     class="dark-blue-container"></div> -->
</section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'
import FlexibleContent from '@/components/FlexibleContent.vue'

export default {
	components: {
		'vue-recaptcha': VueRecaptcha
	},
	data() {
		return {
			name: '',
			email: '',
			phone: '',
			message: '',
			infoBox: {
				type: 0, // 0 - no, 1 - success, 2 - error
				content: ''
			},
			sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
		}
	},
	props: {
		data: Object,
		printClass: Function,
		className: String,
	},
	computed: {
		lang() {
			return this.$store.getters.getLocale;
		}
	},
	methods: {
		onSubmit() {
			this.$refs.recaptcha.execute()
			this.sendLabel = 'Sending...'
		},
		onVerify(recaptchaToken) {
			this.$refs.recaptcha.reset();
			var bodyFormData = new FormData();
			bodyFormData.set('name', this.name);
			bodyFormData.set('email', this.email);
			bodyFormData.set('phone', this.phone);
			bodyFormData.set('message', this.message);
			bodyFormData.set('recaptchaToken', recaptchaToken);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config)
				.then((response) => {
					if (response.data == 'ok') {
						this.infoBox.type = 1
						this.infoBox.content = 'Email was send successfully.'
						this.sendLabel = 'Send'
						this.name = null
						this.email = null
						this.phone = null
						this.message = null
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					} else {
						this.infoBox.type = 2
						this.infoBox.content = 'Server side error'
						this.sendLabel = 'Send'
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					}

				})
				.catch((err) => {
					this.infoBox.type = 2
					this.infoBox.content = 'Cannot send email, please try again later.'
					this.sendLabel = 'Send'
					setTimeout(() => {
						this.infoBox.type = 0
					}, 3000)
					console.log(getErrorMessage(err));
					//helper to get a displayable message to the user
					function getErrorMessage(err) {
						let responseBody;
						responseBody = err.response;
						if (!responseBody) {
							responseBody = err;
						} else {
							responseBody = err.response.data || responseBody;
						}
						return responseBody.message || JSON.stringify(responseBody);
					}
				})
		},
		onExpired() {
			console.log('Expired')
		}
	}
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import '@/styles/framework/breakpoints.scss';

.dark-blue-container {
    background-color: $light-dark;
    width: 30%;
    position: absolute;
    height: 300px;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.book {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;
    &__gray-container {
        padding: 140px 200px 140px 400px;
        background-color: $bookBg;
        max-width: 1350px;
        width: 70%;
        position: relative;
        z-index: 5;
        @include breakpoint-max("large") {
            max-width: 80%;
            width: 80%;
        }
        @include touch {
            width: 100%;
            max-width: 100%;
            padding: 100px 40px 40px 400px;
        }
        @include breakpoint-max("xs-tablet") {
            padding: 80px 40px;
        }
        @include mobile {
            padding: 60px 40px;
        }
        & h2 {
            margin-bottom: 10px;
        }
        & img {
            position: absolute;
            left: -20%;
            top: 150px;
            @include touch {
                top: 110px;
            }
            @include breakpoint-max("xs-tablet") {
                display: none;
            }
        }
    }
    &__intro {
        /deep/ p {
            font-size: 3rem;
            line-height: 1.35;
            margin-bottom: 50px;
            font-family: 'BlackerProDis-LtIt';
            @include touch {
                font-size: 2.4rem;
            }
        }
    }
    &__text {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid $inputBorder;
        border-radius: 0;
        background-color: $bookBg;
        margin-bottom: 30px;
        color: $black;
        width: 450px;
        outline: none;
        padding: 6px 3px;
        font-size: 1.6rem;
        font-family: Montserrat;
        @include touch {
            width: 100%;
        }
        &::placeholder {
            font-size: 1.6rem;
            color: #8B8B8B;
            font-family: Montserrat;
        }
    }
    &__select {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid $inputBorder;
        border-radius: 0;
        background-color: $bookBg;
        color: $black;
        width: 450px;
        outline: none;
        font-size: 1.6rem;
        margin-bottom: 30px;
        -webkit-appearance: none;
        @include touch {
            width: 100%;
        }
    }
    &__textarea-container {
        display: flex;
        flex-direction: column;
        label {
            font-size: 1.5rem;
            color: #8B8B8B;
            margin-bottom: 5px;
        }
    }
    &__textarea {
        width: 450px;
        height: 260px;
        border: 1px solid $inputBorder;
        border-radius: 0;
        background-color: $bookBg;
        resize: vertical;
        overflow: auto;
        outline: none;
        -webkit-appearance: none;
        padding: 10px 12px;
        font-size: 16px;
        font-family: Montserrat;
        color: #4a4a4a;
        &::placeholder {
            font-size: 1.6rem;
            color: #8B8B8B;
            font-family: Montserrat;
        }
        @include touch {
            width: 100%;
        }
    }
    &__send[type=submit] {
        background-color: #F5F5F5;
        padding: 4px 0;
        border: none;
        text-decoration: underline;
        font-size: 1.6rem;
        margin-top: 20px;
        -webkit-border-radius: 0;
        -webkit-appearance: none;
        &:hover {
            cursor: pointer;
        }
    }
    .control {
        font-size: 1.5rem;
        a {
            color: $gold;
            font-size: 1.5rem;
        }
        .checkbox {
            margin-top: 10px;
            display: flex;
            align-items: center;
            span {
                margin-left: 5px;
            }
        }
    }
}
</style>

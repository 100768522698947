<template>
<div id="app"
     data-scrollbar="true">
	<the-header />
	<transition name="fade"
	            mode="out-in">
		<router-view :key="$route.params.id" />
	</transition>
</div>
</template>

<script>
import AOS from 'aos'
import TheHeader from '@/components/TheHeader.vue'
export default {
	components: {
		'the-header': TheHeader
	},
	metaInfo() {
		return {
			titleTemplate: this.seoTitleTemplate,
			title: this.title,
			htmlAttrs: {
				lang: this.$store.getters.getLocale
			},
			meta: [{
					name: 'description',
					content: this.seoDescription
				},
				{
					name: 'robots',
					content: this.seoRobots
				},
				{
					name: 'og:title',
					content: this.seoTitleTemplate
				},
				{
					name: 'og:image',
					content: 'https://annettemueller.ch/img/AM_fb.jpg'
				},
				{
					name: 'og:description',
					content: this.seoDescription
				}
			],
			link: [{
				rel: 'icon',
				href: this.optionsFavicon
			}]
		}
	},
	computed: {
		page() {
			let page = this.$store.getters.getPageByPath(this.$route.path)
			if (page === undefined) {
				this.$router.push({
					name: 'error404'
				})
			} else {
				return this.$store.getters.getPageByPath(this.$route.path)
			}
		},
		title() {
			if (this.page !== undefined) {
				return this.page.title
			} else {
				return ''
			}
		},
		seoTitleTemplate() {
			return this.page.seo_title != false ? this.page.seo_title : this.$store.getters.getSeoTitleTemplate
		},
		seoDescription() {
			return this.page.seo_description != false ? this.page.seo_description : ''
		},
		seoRobots() {
			return this.page.seo_no_index != false ? 'noindex,nofollow' : 'index,follow'
		},
		optionsFavicon() {
			return this.$store.getters.getOptionsFavicon
		}
	},
	created() {
		AOS.init();
	}
}
</script>

<style scoped lang="scss">
#app {
    overflow: hidden;
}
</style>

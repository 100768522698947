<template>
    <section class="subpage">
        <div v-if="data.gold_square_left" class="gold-square-left"></div>
        <div v-if="data.gold_square_left_small" class="gold-square-left-small"></div>
        <div v-if="data.gold_square_right" class="gold-square-right"></div>
        <div class="subpage__container custom-container" :class="[data.select_order, data.custom_class]">
            <div class="subpage__image">
                <figure class="image">
                    <img
                        :data-aos="data.image_animation.name"
                        :data-aos-duration="data.image_animation.duration*100"
                        :src="data.image.url"
                        :alt="data.image.alt">
                </figure>
            </div>
            <div class="subpage__text">
                <div class="subpage__box"
                    :data-aos="data.content_animation.name"
                    :data-aos-duration="data.content_animation.duration*100"
                >
                    <h2 v-if="data.header" class="subpage__header" >{{ data.header }}</h2>
                    <p v-if="data.title" class="subpage__title" >{{ data.title }}</p>
                    <div v-if="data.content" v-html="data.content" class="subpage__text-content">
                        {{ data.content }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "IntroSubpage",
    data() {
        return {};
    }
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/fonts.sass";
@import '@/styles/framework/breakpoints.scss';

.mobile-margin-bottom {
    .subpage__text {
        @include touch {
            margin-bottom: 50px;
        }
    }
}
.mobile-text-height-300 {
    @include breakpoint-max("xs-tablet") {
        .subpage__text {
            margin-top: 0;
        }
    }
}
.column-reserve {
    @include breakpoint-max("xs-tablet") {
        flex-direction: column-reverse !important;
        display: flex;
    }
}
.narrow-text {
    justify-content: flex-start;
    .subpage__text {
        max-width: 500px;
    }
}
.gold-square-right {
    background-color: $beigeBg;
    height: 900px;
    width: 75%;
    position: absolute;
    bottom: -74px;
    right: 0;
    z-index: 1;
}
.gold-square-left {
    background-color: $beigeBg;
    height: 900px;
    width: 75%;
    position: absolute;
    bottom: -100px;
    left: 0;
    z-index: 1;
}
.gold-square-left-small {
    background-color: $beigeBg;
    height: 750px;
    width: 75%;
    position: absolute;
    bottom: -14px;
    left: 0;
    z-index: 1;
}
.row {
    flex-direction: row;
    position: relative;
    .subpage__text {
        padding-left: 90px;
        @include touch {
            padding-left: 30px;
        }
    }
}
.row-reverse {
    flex-direction: row-reverse;
    .subpage__text {
        padding-right: 90px;
        @include breakpoint-max("xs-tablet") {
            padding-right: 20px;
        }
    }
}
.subpage {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 50px 0;
    z-index: 1;
    @include mobile {
        flex-direction: column;
        padding: 0;
    }
    &__container {
        z-index: 1;
        @include mobile {
            flex-direction: column;
        }
    }
    &__image {
        width: 50%;
        display: flex;
        @include breakpoint-max("xs-tablet") {
            padding: 30px;
        }
        @include touch {
            width: 100%;
        }
        img {
            object-fit: contain;
        }
    }
    &__text {
        display: flex;
        align-items: center;
        width: 50%;
        color: $gray;
        @include touch {
            width: 100%;
            margin-top: 0;
            padding: 30px;
        }
        @include mobile {
            padding: 30px;
            margin-top: 20px;
        }
    }
    &__header {
        color: $gold;
        font-size: 2.4rem;
        text-transform: uppercase;
        margin-bottom: 16px;
        @include touch {
            margin-bottom: 10px;
        }
    }
    &__title {
        line-height: 1.35;
        font-size: 3rem;
        font-weight: 400;
        color: $dark;
        font-family: 'BlackerProDis-LtIt';
        @include touch {
            font-size: 2.4rem;
        }
    }
    &__text-content {
        margin-top: 24px;
        color: $gray;
        /deep/ p {
            color: $gray;
            font-size: 1.8rem;
        }
    }
}

</style>

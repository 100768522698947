<template>
<section class="posts">
	<div class="posts__container custom-container"
	     :class="data.custom_class"
	     :data-aos="data.post_animation.name"
	     :data-aos-duration="data.post_animation.duration*100">
		<div class="posts__info-box">
			<h2 class="posts__title">
				<router-link class="posts__title blog_title_link"
				             :to="data.link_to_blog">Blog.</router-link> {{ data.title }}:
			</h2>

		</div>
		<div class="posts__box">
			<div class="posts__item"
			     v-for="(item,key) in posts"
			     :key="key">
				 <div class="">
				 	<img :src="item.thumbnail" :alt="item.title">
				 </div>
				<div class="posts__item-box">
					<h3 @click="$router.push(item.slug)"
					    v-html="item.title"></h3>
					<p class="posts__excerpt" v-html="item.excerpt">{{item.excerpt}}</p>
				</div>
				<router-link v-if="lang == 'de'"
				             class="main-link"
				             :to="item.slug">{{singleLinkText}}</router-link>
			</div>
		</div>
		<div>

			<router-link v-if="data.link_to_blog"
			             :to="data.link_to_blog"
			             class="main-link all-articles">{{allPostsLinkText}}</router-link>
		</div>
	</div>
</section>
</template>

<script>
export default {
	computed: {
		allPostsLinkText() {
			switch (this.lang) {
				case "en":
					return "See all Posts";
				case "de":
					return "Alle Beiträge sehen";
				default:
					return "See all Posts";
			}
		},
		singleLinkText() {
			switch (this.lang) {
				case "en":
					return "Read More";
				case "de":
					return "Weiterlesen";
				default:
					return "Read More";
			}
		},
		posts() {
			return this.$store.getters.getPosts.slice(0, 3);
		},
		postsPage() {
			return this.$store.getters.getOptionsPostsPage;
		},
		lang() {
			return this.$store.getters.getLocale;
		}
	}
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.padding-extra-top {
    padding-top: 400px;
}
.all-articles {
    color: $gold;
    // text-align: right;
    float: right;
    &::after {
        background-color: $gold;
    }
}
.blog_title_link {
    color: $gold !important;
    margin-right: 5px;
    &::after {
        display: block;
        content: '';
        height: 1px;
        width: 100%;
        background-color: $gold;
    }
}
.posts__title {
    display: flex;
}
.posts {
    background-color: $light-dark;
    padding: 100px 20px;
    @include mobile {
        padding: 40px 20px;
    }
    &__excerpt {
        color: white;
        font-size: 1.2rem;
    }
    &__container {
        display: flex;
        flex-direction: column;
    }
    &__title {
        color: white;
        font-size: 3.4rem;
        font-family: 'Blacker';
        text-transform: capitalize;
    }
    &__info-box {
        padding: 30px 10px 0;
        display: flex;
        justify-content: space-between;
        @include mobile {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .main-link {
            @include mobile {
                margin-top: 0;
                padding-top: 0;
                margin-bottom: 20px;
            }
            color: $gold;
            &::after {
                background-color: $gold;
                width: 100%;
                // max-width: 100px;
            }
        }
    }
    &__box {
        display: flex;
        @include mobile {
            flex-direction: column;
        }
    }
	&__item-box {
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		align-items: baseline;
	}
    &__item {
        width: 33.33333%;
        padding: 10px 20px 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include mobile {
            width: 100%;
        }
        h3 {
            color: white;
            font-size: 1.8rem;
            text-transform: inherit;
        }
        .main-link {
            color: $gold;
            &::after {
                background-color: $gold;
            }
        }
    }
}
</style>

<template>
    <section class="text-image" :class="data.custom_class">
        <div class="medium-gold-square-left"></div>
        <div class="text-image__container custom-container">
            <div class="text-image__text">
                <div class="text-image__content"
                    :data-aos="data.content_animation.name"
                    :data-aos-duration="data.content_animation.duration*100"
                    v-html="data.content"></div>
            </div>
            <div class="text-image__image">
                <figure>
                    <img
                        :data-aos="data.image_animation.name"
                        :data-aos-duration="data.image_animation.duration*100"
                        :src="data.image.url"
                        :alt="data.image.alt">
                </figure>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "TextImageColumn",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import '@/styles/framework/breakpoints.scss';
 .margin {
    .text-image__text {
        margin: 130px auto;
        margin-bottom: 140px;
        @include breakpoint-max('tablet') {
            margin: 60px auto;
        }
    }
    .medium-gold-square-left {
        background-color: $beigeBg;
        height: 730px;
        width: 32%;
        position: absolute;
        top: -250px;
        left: 0;
        z-index: 0;
        @include breakpoint-max('tablet') {
            display: none;
        }
    }
}

.list-box {
    .text-image__text {
        margin: 0 auto;
        margin-top: 40px;
        @include breakpoint-max('tablet') {
            margin: 60px auto;
        }
    }
    /deep/.text-image__content {
        span {
            color: #1A2331;
            font-size: 2.4rem;
            text-transform: uppercase;

        }
        ul {
            margin-top: 20px;
           li {
               position: relative;
               padding-left: 20px;
               color: $gray;
               margin-bottom: 24px;
               &::before {
                   content: "";
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    transform: translateY(-50%);
                    border-radius: 50%;
                    width: 4px;
                    height: 4px;
                    background-color: #BA9B78;
               }
           }
       }
   }
}

.reserve {
    .text-image__container {
        flex-direction: column-reverse;
    }
}
.text-left-padding {
    .text-image__text {
        padding-left: 65px;
        @include touch {
            padding: 30px;
            margin: 0;
        }
    }
}
.text-image {
    position: relative;
    &__container {
        flex-direction: column;
    }
    &__text {
        max-width: 900px;
        margin: 0 auto;
        margin-bottom: 98px;
        /deep/ p {
            font-size: 1.8rem;
            color: $gray;
        }
        @include touch {
            padding: 30px;
            margin-bottom: 0;
            text-align: left;
        }
    }
    &__image {
        display: flex;
        margin-bottom: 100px;
        justify-content: center;
        @include touch {
            padding: 0 30px;
            margin-bottom: 0;
        }
    }
}
</style>

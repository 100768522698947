<template>
<div>
	<header class="header">
		<div class="custom-container header__flex">
			<div class="header__logo"
			     :class="{ nodisplay: burger }">
				<router-link to="/">
					<img :src="logo"
					     alt="Annette Muller">
				</router-link>
			</div>
			<div class="header__button">
				<a href="https://www.facebook.com/annette.muller.142"
				   class="facebook_logo" target="_blank"><svg version="1.1"
					     id="Layer_1"
					     xmlns="http://www.w3.org/2000/svg"
					     xmlns:xlink="http://www.w3.org/1999/xlink"
					     x="0px"
					     y="0px"
					     viewBox="0 0 310 310"
					     style="enable-background:new 0 0 310 310;"
					     xml:space="preserve">
						<g id="XMLID_834_">
							<path id="XMLID_835_"
							      d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
		c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
		V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
		C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
		c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z" />
						</g>

					</svg></a>
				<!-- Na szybko -->
				<a href="/"
				   v-if="locale == 'en'"
				   class="language_switch">DE</a>
				<a href="/en"
				   class="language_switch"
				   v-else>EN</a>
				<button :class="{ active: burger }"
				        @click="burger = !burger">Menu</button>
			</div>
		</div>
	</header>
	<transition v-on:enter="menuEnter"
	            v-on:leave="menuLeave"
	            appear>
		<div class="menu"
		     v-if="burger">
			<div class="menu__logo">
				<router-link to="/"
				             @click.native="burger = !burger">
					<img :src="logo"
					     alt="Annette Muller">
				</router-link>
			</div>
			<div class="menu__line"></div>
			<nav class="menu__nav">
				<ul>
					<li v-for="(menuItem, menuKey) in mainMenu"
					    :key="menuKey">
						<a :href="'/'+menuLocale+menuItem.url"
						   class="menu__link"
						   @click.prevent="navigateTo('/'+menuItem.url)"
						   v-html="menuItem.title"></a>
						<!-- <router-link
                                :to="menuItem.url"
                                class="menu__link"
                                v-html="menuItem.title"
                                @click.native="burger = !burger"
                            >
                            </router-link> -->
					</li>
				</ul>
			</nav>
		</div>
	</transition>
</div>
</template>

<script>
import {
	TimelineLite,
	Back,
	Elastic,
	Expo
} from "gsap"

export default {
	data() {
		return {
			burger: false,
			mobile: false
		}
	},
	methods: {
		menuEnter(el, done) {
			let logo = document.querySelector(".menu__logo");
			let line = document.querySelector(".menu__line");
			const tl = new TimelineMax({
				onComplete: done
			})


			tl.set(logo, {
				opacity: 0
			})
			tl.set(line, {
				scaleX: 0,
				opacity: 0
			})
			tl.set(el, {
				scaleY: 0,
				transformOrigin: "0% top"
			})
			tl.to(el, .8, {
				scaleY: 1,
				transformOrigin: "100% top",
				ease: Power2.easeOut
			})

			if (!this.mobile) {
				tl.to(logo, 1.5, {
					opacity: 1,
					ease: Power2.easeOut
				})
				tl.to(line, 1.4, {
					scaleX: 1,
					opacity: 1,
					transformOrigin: "left",
					ease: Power2.easeOut
				}, "-=1")
				tl.staggerFrom(".menu__link", 2, {
					opacity: 0
				}, .15, "-=1.3")
			} else {
				tl.staggerFrom(".menu__link", 2, {
					opacity: 0
				}, .15, .6)
			}

		},
		menuLeave(el, done) {
			let logo = document.querySelector(".menu__logo");
			let line = document.querySelector(".menu__line");

			const tl3 = new TimelineMax({
				onComplete: done
			})

			tl3.to(".menu__link", .8, {
				opacity: 0
			}, 0.15)

			tl3.to(logo, .8, {
				opacity: 0
			}, "-=.8")
			tl3.to(line, .8, {
				opacity: 0
			}, "-=.8")
			tl3.to(el, .5, {
				scaleY: 0,
				ease: Power2.easeOut,
				onComplete: done
			})
		},
		headerHandler() {
			console.log("test");
		},
		langHomeUrl(home_url) {
			return this.publicPath + home_url
		},
		navigateTo(url) {
			const publicPath = '/' + this.$store.getters.getPublicPath
			const locale = this.$store.getters.getLocale
			if (url != '#') {
				this.$router.push(url)
				this.burger = false
			}
		},
		resize(e) {
			console.log(e.target.outerWidth)
			if (e.target.outerWidth <= 1080) {
				this.mobile = true
			} else {
				this.mobile = false
			}
		}
	},
	created() {
		if (window.innerWidth <= 1080) {
			this.mobile = true
		} else {
			this.mobile = false
		}
		window.addEventListener("resize", this.resize)
	},
	destroyed() {
		window.removeEventListener("resize", this.resize)
	},
	computed: {
		languages() {
			return this.$store.getters.getLanguages
		},
		options() {
			return this.$store.getters.getOptions
		},
		logo() {
			return this.$store.getters.getOptionsLogo
		},
		locale() {
			return this.$store.getters.getLocale
		},
		menuLocale() {
			return (this.locale != this.$store.getters.getOptions.default_language) ? this.locale + '/' : ''
		},
		publicPath() {
			return this.$store.getters.getPublicPath
		},
		mainMenu() {
			return this.$store.getters.getMainMenu
		}
	},
}
</script>

<style scoped lang="scss">
@import '@/styles/framework/variables.sass';
@import '@/styles/framework/breakpoints.scss';
.facebook_logo svg {
    width: 15px;
    margin-bottom: -1px;
    fill: #A8A8A8;
}
.header {
    position: fixed;
    width: 100%;
    background-color: #FFF;
    z-index: 990;
    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0;
        box-sizing: border-box;
        @include touch {
            padding: 25px;
        }
        @include mobile-m {
            padding: 25px 5px;
        }
    }
    &__logo {
        opacity: 1;
        transition: opacity 1s;
        @include breakpoint-max("mobile") {
            max-width: 120px;
        }
        &.nodisplay {
            opacity: 0;
        }
        @include breakpoint-max("tablet") {
            opacity: 1 !important;
        }
    }
    &__button {
        z-index: 999;
        a {
            padding: 0 15px;
            color: #A8A8A8;
            border-right: 1px solid #A8A8A8;
            @include mobile-m {
                padding: 0 5px;
            }
        }
        .language_switch {
            // border-left: 1px solid #A8A8A8;
            margin-right: 10px;
        }
        button {
            font-family: 'Montserrat', sans-serif;
            font-size: 18px;
            text-transform: uppercase;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            position: relative;
            width: 140px;
            text-align: left;
            outline: none;
            transition: color 0.6s ease;
            z-index: 999;
            @include breakpoint-max("mobile") {
                font-size: 14px;
                width: 95px;
            }
            &.active {
                color: $gold;
            }
            &::after,
            &::before {
                transition: all 0.6s ease;
                content: '';
                width: 60px;
                height: 1px;
                left: 80px;
                background-color: $gold;
                position: absolute;
                top: 10px;
                @include breakpoint-max("mobile") {
                    width: 40px;
                    left: 62px;
                }
            }
            &::before {
                opacity: 0;
            }
            &.active {
                &::before {
                    transform: rotate(-45deg);
                    opacity: 1;
                    background-color: $dark;
                }
                &::after {
                    transform: rotate(45deg);
                    background-color: $dark;
                }
            }
        }
    }
}

.menu {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 900;
    background-color: #FFF;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    display: flex;
    align-items: center;
    &__logo {
        @include breakpoint-max("tablet") {
            display: none;
        }
        img {
            width: 400px;
        }
    }
    &__line {
        height: 1px;
        background-color: $gold;
        width: 122px;
        margin: 80px;
        @include breakpoint-max("tablet") {
            display: none;
        }
    }
    &__nav {
        font-family: 'Blacker';
        font-size: 35px;
        line-height: 90px;
        @include breakpoint-height-max("large") {
            font-size: 24px;
            line-height: 70px;
        }
        @include breakpoint-max("large") {
            font-size: 24px;
            line-height: 70px;
        }
        @include breakpoint-max("tablet") {
            text-align: center;
        }
        @include breakpoint-max("mobile") {
            font-size: 18px;
            line-height: 44px;
        }
    }
    &__link {
        display: inline;
        position: relative;
        overflow: hidden;
        transition: color 0.3s;
        color: $dark;
        font-size: 3rem;
        @include mobile-m {
            font-size: 2.4rem;
        }
        @include breakpoint-max("mobile") {
            font-size: 1.8rem;
        }
        &:hover {
            color: $gold;
        }
        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            right: 0;
            width: 0;
            bottom: -5px;
            background: $gold;
            height: 1px;
            transition-property: width;
            transition-duration: 0.6s;
            transition-timing-function: ease-out;
            @include breakpoint-max("tablet") {
                display: none;
            }
        }
        &:active::after,
        &:focus::after,
        &:hover::after {
            left: 0;
            right: auto;
            width: 100%;
            @include breakpoint-max("tablet") {
                display: none;
            }
        }
    }
}
</style>

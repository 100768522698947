<template>
    <section class="section">
        <div class="container">
            <div class="columns is-centered is-vcentered is-flex-mobile">
                <div class="column">
                    <h1 class="title has-text-centered">404<br />Page not found</h1>
                    <p class="has-text-centered">
                        Please go to <a href.prevent="#" @click="$router.push('/')">Home</a>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: 'Page not found',
            meta: [
                {
                    'name': 'robots',
                    'content': 'noindex, nofollow'
                }
            ]
        }
    }
}
</script>

<template>
<section class="quote"
         :class="data.custom_class">
	<div class="custom-container">
		<div class="quote__container"
		     :data-aos="data.quote_animation.name"
		     :data-aos-duration="data.quote_animation.duration*100">
			<h2>{{ data.title }}</h2>
			<div v-html="data.content"></div>
			<router-link class="main-link"
			             :to="data.link_href">{{ data.link_text }}</router-link>
		</div>
	</div>
</section>
</template>

<script>
export default {
	name: "Quote",
	data() {
		return {};
	}
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.padding-extra-y {
    padding: 100px 0;
    @include touch {
        padding: 40px 0;
    }
}
.quote {
    position: relative;
    &__container {
        max-width: 900px;
        padding: 30px 0;
        text-align: center;
        font-family: 'Blacker';
        & h2 {
            font-family: Montserrat;
        }
        @include touch {
            padding: 30px 20px;
        }
        /deep/ & p {
            font-size: 3.4rem;
            line-height: 1.35;
            font-family: 'BlackerProDis-LtIt';
            @include touch {
                font-size: 2.4rem;
                line-height: 1.35;
            }
        }
    }
}
</style>

<template>
    <section class="partners" :id="data.id">
        <div class="partners__contianer custom-container"
            :data-aos="data.partners_animation.name"
            :data-aos-duration="data.partners_animation.duration*100"
        >
            <h2>{{ data.title }}</h2>
            <div class="partners__content">
                <div class="partners__box" v-for="(partner, key) in data.partners" :key="key">
                    <a :href="partner.href" target="_blank">
                        <img
                            :data-aos="partner.name"
                            :data-aos-duration="partner.duration*100"
                            :src="partner.image.url"
                            :alt="partner.image.alt">
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Partners",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.partners {
    color: $gold;
    text-transform: uppercase;
    padding-bottom: 20px;
    font-size: 2rem;
    &__contianer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 80px 20px;
        @include touch {
            padding: 50px 30px;
        }
        @include mobile {
            padding: 40px 20px;
        }
        h2 {
            margin-bottom: 45px;
            @include touch {
                margin-bottom: 30px;
            }
        }
    }
    &__content {
        display: flex;
        justify-content: center;
        @include touch {
            flex-direction: column;
        }
    }
    &__box {
        margin: 0 30px;
        padding: 10px;
        a {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img {
            max-height: 100px;
        }
    }
}
</style>

<template>
<div>
	<flexible-content />
	<section class="section is-paddingless post view-single"
	         v-if="page && page.content">
		<div class="gold-square-left"></div>



		<!-- <div class="container" v-if="page.thumbnail">
                <div class="columns is-centered">
                    <div class="column is-10">
                        <div class="content image-wrapper">
                            <figure class="image">
                                <img :src="page.thumbnail" :alt="page.title">
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-10">
                        <div class="content">
                            <h1 class="title" v-html="page.title"></h1>
                            <div v-html="page.content"></div>
                        </div>
                    </div>
                </div>
            </div> -->
		<div class="main-container">
			<div class="post__box">
				<div class="post__img">
					<figure data-aos="fade-right"
					        data-aos-duration="1000">
						<img :src="page.thumbnail"
						     :alt="page.title">
					</figure>
				</div>
			</div>
			<div class="post__title">
				<div class="post__title-box"
				     data-aos="fade-down"
				     data-aos-duration="1000">
					<span class="post__subtitle">blog post</span>
					<h2 v-html="page.title"></h2>
					<span>{{ page.date }}</span>
				</div>
			</div>
			<div data-aos="fade-left"
			     data-aos-duration="1000"
			     class="post__content"
			     v-html="page.content"></div>
			<div class="post__btn-box"
			     data-aos="fade-down"
			     data-aos-duration="1000">
				<div class="post__btn">
					<router-link :to="prevPost">
						<button v-if="lang == 'en'">Previous</button>
						<button v-if="lang == 'de'">Vorher</button>
					</router-link>
					<span>/</span>
					<router-link :to="nextPost">
						<button v-if="lang == 'en'">Next Post</button>
						<button v-if="lang == 'de'">Nächster Beitrag</button>
					</router-link>
				</div>
			</div>
		</div>
	</section>
	<section data-aos="fade-left"
	         data-aos-duration="1000"
	         class="book"
	         v-if="(page && page.content) && (lang == 'en')">
		<div class="book__gray-container">
			<h2>Book a session</h2>
			<div class="book__intro"></div>
			<figure>
				<img src="@/assets/booking-section-2.jpg"
				     alt="Anette Muller">
			</figure>
			<form @submit.prevent="onSubmit">
				<div class="field">
					<div class="control">
						<input class="book__text"
						       type="text"
						       placeholder="First Name"
						       name="name"
						       v-model="name"
						       required>
					</div>
				</div>
				<div class="field">
					<div class="control">
						<input class="book__text"
						       type="text"
						       placeholder="E-mail adress"
						       name="email"
						       v-model="email"
						       required>
					</div>
				</div>
				<div class="field">
					<div class="control">
						<input class="book__text"
						       type="text"
						       placeholder="Phone number"
						       name="email"
						       v-model="phone"
						       required>
					</div>
				</div>
				<select class="book__select"
				        v-model="subject">
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
				</select>
				<div class="field">
					<div class="book__textarea-container control">
						<textarea class="book__textarea"
						          name="message"
						          rows="8"
						          cols="80"
						          v-model="message"
						          placeholder="Message"></textarea>
					</div>
				</div>
				<div class="field">
					<div class="control">
						This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.<br />
						<label class="checkbox">
							<input class="book__checkbox"
							       type="checkbox"
							       name="agreement-1"
							       id="agreement-1"
							       required>
							I agree to the <a href="/terms-and-conditions"> terms and conditions</a>
						</label>
					</div>
				</div>
				<vue-recaptcha ref="recaptcha"
				               @verify="onVerify"
				               @expired="onExpired"
				               size="invisible"
				               :sitekey="sitekey">
				</vue-recaptcha>
				<div class="field">
					<div class="control">
						<input class="book__send"
						       type="submit"
						       name="submit"
						       value="Book a Session">
					</div>
				</div>
				<transition name="fade"
				            mode="out-in">
					<article class="message"
					         :class="{'is-success':infoBox.type == 1,'is-danger':infoBox.type == 2}"
					         v-if="infoBox.type != 0"
					         @click="infoBox.type = 0">
						<div class="message-header">
							<p>{{ infoBox.type == 1 ? 'Sucess' : 'Error' }}</p>
							<button class="delete"
							        aria-label="delete"></button>
						</div>
						<div class="message-body">
							{{ infoBox.content }}
						</div>
					</article>
				</transition>
			</form>
		</div>
		<div class="dark-blue-container"></div>
	</section>
	<section class="book"
	         v-if="(page && page.content) && (lang == 'de')">
		<div class="book__gray-container"
		     data-aos="fade-left"
		     data-aos-duration="1000">
			<h2>Eine Sitzung buchen</h2>
			<div class="book__intro"></div>
			<figure>
				<img src="@/assets/booking-section-2.jpg"
				     alt="Anette Muller">
			</figure>
			<form @submit.prevent="onSubmit">
				<div class="field">
					<div class="control">
						<input class="book__text"
						       type="text"
						       placeholder="Vorname, Name"
						       name="name"
						       v-model="name"
						       required>
					</div>
				</div>
				<div class="field">
					<div class="control">
						<input class="book__text"
						       type="text"
						       placeholder="E-mail"
						       name="email"
						       v-model="email"
						       required>
					</div>
				</div>
				<div class="field">
					<div class="control">
						<input class="book__text"
						       type="text"
						       placeholder="Telefonnummer"
						       name="phone"
						       v-model="phone"
						       required>
					</div>
				</div>
				<div class="field">
					<div class="book__textarea-container control">
						<textarea class="book__textarea"
						          name="message"
						          rows="8"
						          cols="80"
						          v-model="message"
						          placeholder="Nachricht"></textarea>
					</div>
				</div>
				<div class="field">
					<div class="control">
						Diese Seite ist über reCAPTCHA gesichert und es gelten die <a href="https://policies.google.com/privacy">Google Richtlinien</a> und <a href="https://policies.google.com/terms">Nutzungsbedingungen</a>. <br />
						<label class="checkbox">
							<input class="book__checkbox"
							       type="checkbox"
							       name="agreement-1"
							       id="agreement-1"
							       required>
							Ich stimme den Nutzungsbedingungen und Konditionen zu</a>
						</label>
					</div>
				</div>
				<vue-recaptcha ref="recaptcha"
				               @verify="onVerify"
				               @expired="onExpired"
				               size="invisible"
				               :sitekey="sitekey">
				</vue-recaptcha>
				<div class="field">
					<div class="control">
						<input class="book__send"
						       type="submit"
						       name="submit"
						       value="Eine Sitzung buchen">
					</div>
				</div>
				<transition name="fade"
				            mode="out-in">
					<article class="message"
					         :class="{'is-success':infoBox.type == 1,'is-danger':infoBox.type == 2}"
					         v-if="infoBox.type != 0"
					         @click="infoBox.type = 0">
						<div class="message-header">
							<p>{{ infoBox.type == 1 ? 'Sucess' : 'Error' }}</p>
							<button class="delete"
							        aria-label="delete"></button>
						</div>
						<div class="message-body">
							{{ infoBox.content }}
						</div>
					</article>
				</transition>
			</form>
		</div>
		<div class="dark-blue-container"></div>
	</section>
	<section class="contact-section image-align-right"
	         v-if="(page && page.content) && (lang == 'en')">
		<div class="contact-section__container custom-container">
			<div data-aos="fade-left"
			     data-aos-duration="1000"
			     class="contact-section__image-box">
				<figure class="image">
					<img src="@/assets/anette-contact-1.jpg"
					     alt="Anette Muller" />
				</figure>
			</div>
			<div data-aos="fade-right"
			     data-aos-duration="2000"
			     class="contact-section__text-box">
				<h2>Contact</h2>
				<p>Parum fugiamenis quunt, audiciis maxim vera qui dolo ius ressima ximus. Lorpos quam reperem voluptae volo ea dolenis ciligna taquatus eic testotae nostio. At modipist lamus dolorae sim conet iur aspidi quis eos volupta epudae rerum</p>
				<p class="main-link">
					<router-link to="/en/contact">Learn More</router-link>
				</p>
			</div>
		</div>
	</section>
	<section class="contact-section image-align-right"
	         v-if="(page && page.content) && (lang == 'de')">
		<div class="contact-section__container custom-container">
			<div data-aos="fade-left"
			     data-aos-duration="1000"
			     class="contact-section__image-box">
				<figure class="image">
					<img src="@/assets/anette-contact-1.jpg"
					     alt="Anette Muller" />
				</figure>
			</div>
			<div data-aos="fade-right"
			     data-aos-duration="2000"
			     class="contact-section__text-box">
				<h2>Kontakt</h2>
				<p>Annette Müller</p>
				<p>Zollikerstrasse 234</p>
				<p>8008 Zürich</p>
				<p>
					<a href="mailto:info@annettemueller.ch">info@annettemueller.ch</a>
				</p>
				<p>
					<a href="tel:+41786611320">+41 78 661 13 20</a>
				</p>
				<p class="main-link">
					<router-link to="/contact">Weiterlesen</router-link>
				</p>
			</div>
		</div>
	</section>
	<!-- <section class="partners"
	         id="partners"
	         v-if="(page && page.content) && (lang == 'en')">
		<div class="partners__contianer custom-container">
			<h2 data-aos="zoom-in"
			    data-aos-duration="0">Partners</h2>
			<div class="partners__content">
				<div data-aos="zoom-in"
				     data-aos-duration="500"
				     class="partners__box">
					<a href="https://www.deinmedium.ch/"
					   target="_blank">
						<img src="@/assets/partners1.png"
						     alt="deinmedium logo">
					</a>
				</div>
				<div data-aos="zoom-in"
				     data-aos-duration="1000"
				     class="partners__box">
					<a href="https://www.soundofshells.com/"
					   target="_blank">
						<img src="@/assets/partners2.jpg"
						     alt="soundofshells logo">
					</a>
				</div>
				<div data-aos="zoom-in"
				     data-aos-duration="1500"
				     class="partners__box">
					<a href="https://www.bpv.ch/"
					   target="_blank">
						<img src="@/assets/partners3.png"
						     alt="bpv logo">
					</a>
				</div>
			</div>
		</div>
	</section>
	<section class="partners"
	         id="partners"
	         v-if="(page && page.content) && (lang == 'de')">
		<div class="partners__contianer custom-container">
			<h2 data-aos="zoom-in"
			    data-aos-duration="0">Partner</h2>
			<div class="partners__content">
				<div data-aos="zoom-in"
				     data-aos-duration="500"
				     class="partners__box">
					<a href="https://www.deinmedium.ch/"
					   target="_blank">
						<img src="@/assets/partners1.png"
						     alt="deinmedium logo">
					</a>
				</div>
				<div data-aos="zoom-in"
				     data-aos-duration="1000"
				     class="partners__box">
					<a href="https://www.soundofshells.com/"
					   target="_blank">
						<img src="@/assets/partners2.jpg"
						     alt="soundofshells logo">
					</a>
				</div>
				<div data-aos="zoom-in"
				     data-aos-duration="1500"
				     class="partners__box">
					<a href="https://www.bpv.ch/"
					   target="_blank">
						<img src="@/assets/partners3.png"
						     alt="bpv logo">
					</a>
				</div>
			</div>
		</div>
	</section>
	<section class="partners" id="partners">
		<div class="partners__contianer custom-container"
			 data-aos="fade-up"
			 data-aos-duration="1000"
		>
			<h2 v-html="partners.title" v-if="lang == 'en'"></h2>
			<h2 v-else>Partner</h2>
			<div class="partners__content">
				<div class="partners__box" v-for="(partner, key) in partners.partners" :key="key">
					<a :href="partner.href" target="_blank">
						<img
							:src="partner.image"
							:data-aos="partner.name"
                            :data-aos-duration="partner.duration*100"
							alt="Partner"
						>
					</a>
				</div>
			</div>
		</div> -->
	</section>
</div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import FlexibleContent from '@/components/FlexibleContent.vue'
import axios from 'axios'

export default {
	components: {
		'flexible-content': FlexibleContent,
		'vue-recaptcha': VueRecaptcha
	},
	data() {
		return {
			name: '',
			email: '',
			message: '',
			phone: '',
			infoBox: {
				type: 0, // 0 - no, 1 - success, 2 - error
				content: ''
			},
			sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
		}
	},
	props: {
		data: Object,
		printClass: Function,
		className: String,
	},
	methods: {
		onSubmit() {
			this.$refs.recaptcha.execute()
			this.sendLabel = 'Sending...'
		},
		onVerify(recaptchaToken) {
			this.$refs.recaptcha.reset();
			var bodyFormData = new FormData();
			bodyFormData.set('name', this.name);
			bodyFormData.set('email', this.email);
			bodyFormData.set('phone', this.phone);
			bodyFormData.set('message', this.message);
			bodyFormData.set('recaptchaToken', recaptchaToken);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config)
				.then((response) => {
					if (response.data == 'ok') {
						this.infoBox.type = 1
						this.infoBox.content = 'Email was send successfully.'
						this.sendLabel = 'Send'
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					} else {
						this.infoBox.type = 2
						this.infoBox.content = 'Server side error'
						this.sendLabel = 'Send'
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					}

				})
				.catch((err) => {
					this.infoBox.type = 2
					this.infoBox.content = 'Cannot send email, please try again later.'
					this.sendLabel = 'Send'
					setTimeout(() => {
						this.infoBox.type = 0
					}, 3000)
					console.log(getErrorMessage(err));
					//helper to get a displayable message to the user
					function getErrorMessage(err) {
						let responseBody;
						responseBody = err.response;
						if (!responseBody) {
							responseBody = err;
						} else {
							responseBody = err.response.data || responseBody;
						}
						return responseBody.message || JSON.stringify(responseBody);
					}
				})
		},
		onExpired() {
			console.log('Expired')
		}
	},
	computed: {
		posts() {
			return this.$store.getters.getPosts
		},
		partners() {
			return this.$store.getters.getOptionsPartners
		},
		page() {
			return this.$store.getters.getPageByPath(this.$route.path)
		},
		lang() {
			return this.$store.getters.getLocale;
		},
		postPosition() {
			const allPosts = this.posts
			let pos = allPosts.map(function(e) {
					return e.id
				})
				.indexOf(this.page.id)
			return pos
		},
		nextPost() {
			if (this.postPosition + 1 > this.posts.length - 1) {
				return this.posts[0].slug
			} else {
				return this.posts[this.postPosition + 1].slug
			}
		},
		prevPost() {
			if (this.postPosition - 1 < 0) {
				return this.posts[this.posts.length - 1].slug
			} else {
				return this.posts[this.postPosition - 1].slug
			}
		}
	}
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import '@/styles/framework/breakpoints.scss';

.main-container {
    margin: 0 100px;
    z-index: 10;
    @include breakpoint-max('s-tablet') {
        margin: 0 60px;
    }
    @include breakpoint-max('mobile') {
        margin: 0 40px;
    }
}

.post {
    margin: 100px 0;

    &__content,
    &__img {
        max-width: 900px;

        /deep/ p {
            margin-bottom: 1.6rem;
            color: #8B8B8B;
            font-size: 1.8rem;
        }
    }
    &__box {
        width: 100%;
        display: flex;
    }
    &__img {
        margin: 0 auto;
        z-index: 10;
        img {
            width: 100%;
        }
    }
    &__title {
        margin-top: 10px;
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: row;
    }
    &__title-box {
        z-index: 10;
        text-align: center;
        margin: 20px 0 30px;
        max-width: 900px;
        h2 {
            margin: 0;
            text-transform: capitalize;
            font-style: italic;
            line-height: 1.35;
            font-size: 2.6rem;
            font-weight: 400;
            color: #1A2331;
            font-family: 'Blacker';
        }
        p {
            font-family: 'Blacker', sans-serif;
            font-size: 2.4rem;
            margin: 14px 0;
        }
        span {
            color: $gray;
            display: block;
            margin-top: 1rem;
        }
    }
    &__subtitle {
        color: $gold !important;
        font-size: 2rem;
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 1rem;
        display: block;
        margin-top: 0 !important;
    }
    &__content {
        margin: 0 auto;
        position: relative;
        color: $grey;
    }
    &__image {
        float: left;
        width: 370px;
        height: 238px;
        padding-right: 20px;
        padding-bottom: 20px;
        @include breakpoint-max('s-tablet') {
            float: none;
            width: 100%;
            height: 100%;
        }
    }
    &__btn-box {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
    &__btn {
        // border-bottom: 1px solid #000;
        z-index: 1;
        span {
            margin: 0 10px;
        }
        button {
            cursor: pointer;
            background-color: transparent;
            border: none;
            outline: none;
            font-family: 'Blacker', sans-serif;
            font-size: 2rem;
            letter-spacing: 1px;
            @include touch {
                font-size: 1.8rem;
            }
            @include mobile {
                font-size: 1.6rem;
            }
        }
    }
}
.gold-square-left {
    position: absolute;
    top: 180px;
    left: 0;
    height: 100%;
    width: 90%;
    background-color: $beigeBg;
    z-index: 0;
    @include touch {
        display: none;
    }
}
.book {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;
    &__gray-container {
        padding: 140px 400px 140px 100px;
        background-color: $bookBg;
        max-width: 1350px;
        width: 70%;
        position: relative;
        z-index: 5;
        @include breakpoint-max("large") {
            max-width: 80%;
            width: 80%;
        }
        @include touch {
            width: 100%;
            max-width: 100%;
            padding: 100px 400px 40px 100px;
        }
        @include breakpoint-max("xs-tablet") {
            padding: 80px 40px;
        }
        @include mobile {
            padding: 60px 40px;
        }
        & h2 {
            margin-bottom: 10px;
        }
        & img {
            position: absolute;
            right: 0;
            top: 150px;
            @include touch {
                top: 110px;
            }
            @include breakpoint-max("xs-tablet") {
                display: none;
            }
        }
    }
    &__intro {
        /deep/ p {
            font-size: 3rem;
            //font-style: italic;
            line-height: 1.35;
            margin-bottom: 50px;
            font-family: 'BlackerProDis-LtIt';
            @include touch {
                font-size: 2.4rem;
            }
        }
    }
    &__text {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid $inputBorder;
        border-radius: 0;
        background-color: $bookBg;
        margin-bottom: 30px;
        color: $black;
        width: 450px;
        outline: none;
        padding: 6px 0;
        font-size: 1.6rem;
        font-family: Montserrat;
        @include touch {
            width: 100%;
        }
        &::placeholder {
            font-size: 1.6rem;
            color: #8B8B8B;
            font-family: Montserrat;
        }
    }
    &__select {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid $inputBorder;
        border-radius: 0;
        background-color: $bookBg;
        color: $black;
        width: 450px;
        outline: none;
        font-size: 1.6rem;
        margin-bottom: 30px;
        -webkit-appearance: none;
        @include touch {
            width: 100%;
        }
    }
    &__textarea-container {
        display: flex;
        flex-direction: column;
        label {
            font-size: 1.5rem;
            color: #8B8B8B;
            margin-bottom: 5px;
        }
    }
    &__textarea {
        width: 450px;
        height: 260px;
        border: 1px solid $inputBorder;
        border-radius: 0;
        background-color: $bookBg;
        resize: vertical;
        overflow: auto;
        outline: none;
        -webkit-appearance: none;
        padding: 10px 12px;
        font-size: 16px;
        font-family: Montserrat;
        color: #4a4a4a;
        &::placeholder {
            font-size: 1.6rem;
            color: #8B8B8B;
        }
        @include touch {
            width: 100%;
        }
    }
    &__send[type=submit] {
        background-color: #F5F5F5;
        padding: 4px 0;
        border: none;
        text-decoration: underline;
        font-size: 1.6rem;
        margin-top: 20px;
        -webkit-border-radius: 0;
        -webkit-appearance: none;
        &:hover {
            cursor: pointer;
        }
    }
    .control {
        font-size: 1.5rem;
        a {
            color: $gold;
            font-size: 1.5rem;
        }
        .checkbox {
            margin-top: 10px;
            display: flex;
            align-items: center;
            span {
                margin-left: 5px;
            }
        }
    }
}
.partners {
    color: $gold;
    text-transform: uppercase;
    padding-bottom: 20px;
    font-size: 2rem;
    &__contianer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 80px 20px;
        @include touch {
            padding: 50px 30px;
        }
        @include mobile {
            padding: 40px 20px;
        }
        h2 {
            margin-bottom: 45px;
            @include touch {
                margin-bottom: 30px;
            }
        }
    }
    &__content {
        display: flex;
        justify-content: center;
        @include touch {
            flex-direction: column;
        }
    }
    &__box {
        margin: 0 30px;
        padding: 10px;
        a {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img {
            max-height: 100px;
        }
    }
}
.image-align-right {
    .contact-section__image-box {
        display: flex;
        justify-content: flex-end;
        padding: 0 6rem;
        @include touch {
            padding: 0 30px;
            justify-content: flex-start;
        }
    }
}
.contact-section {
    background-color: #1a2331;
    width: 100%;
    &__container {
        @include touch {
            flex-direction: column;
        }
    }
    &__image-box,
    &__text-box {
        width: 50%;
        @include touch {
            width: 100%;
        }
    }
    &__image-box {
        margin-bottom: 0;
        padding-bottom: 0;
        @include touch {
            margin-bottom: 40px;
            justify-content: flex-start;
        }
        & figure {
            margin: 0;
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }
    }
    &__text-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 50px;
        @include touch {
            padding: 0 30px 80px;
        }
        a {
            color: $grey;
            font-size: 1.8rem;
        }
        /deep/ h2 {
            color: #fff;
            font-family: 'Blacker';
            font-size: 3.4rem;
            text-transform: capitalize;
        }
        /deep/ p {
            color: $grey;
            font-size: 1.8rem;
        }
        a {
            color: #7a7a7a;
            font-size: 1.8rem;
        }
        .main-link {
            a {
                width: 95px;
                line-height: 22px;
                color: $gold;
                border-bottom: 1.5px solid $gold;
            }
        }
    }
}
/deep/ .alignleft {
    float: left;
    padding: 10px 10px 10px 0;
    max-width: 350px;
    width: 100%;
    @include breakpoint-max("mobile") {
        padding: 15px 0;
        max-width: none;
    }
}

/deep/ .alignright {
    float: right;
    padding: 10px 0 10px 10px;
    max-width: 350px;
    width: 100%;
    @include breakpoint-max("mobile") {
        padding: 15px 0;
        max-width: none;
    }
}

.partners {
    color: $gold;
    text-transform: uppercase;
    padding-bottom: 20px;
    font-size: 2rem;
    &__contianer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 80px 20px;
        @include touch {
            padding: 50px 30px;
        }
        @include mobile {
            padding: 40px 20px;
        }
        h2 {
            margin-bottom: 45px;
            @include touch {
                margin-bottom: 30px;
            }
        }
    }
    &__content {
        display: flex;
        justify-content: center;
        @include touch {
            flex-direction: column;
        }
    }
    &__box {
        margin: 0 30px;
        padding: 10px;
        a {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img {
            max-height: 100px;
        }
    }
}
</style>

<template>
<section class="contact-section"
         :class="data.custom_class">
	<div class="contact-section__container custom-container">
		<div class="contact-section__image-box"
		     :style="{'order': data.order_image}">
			<figure class="image">
				<img :data-aos="data.image_animation.name"
				     :data-aos-duration="data.image_animation.duration*100"
				     :src="data.image.url"
				     :alt="data.image.alt" />
			</figure>
		</div>
		<div :data-aos="data.content_animation.name"
		     :data-aos-duration="data.content_animation.duration*100"
		     class="contact-section__text-box"
		     :style="{'order': data.order_content}">
			<div v-html="data.content"></div>
			<router-link class="main-link"
			             :to="data.link.href">{{data.link.text}}</router-link>
		</div>
	</div>
</section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.image-align-right {
    .contact-section__image-box {
        display: flex;
        justify-content: flex-end;
        padding: 0 6rem;
        @include touch {
            padding: 0 30px;
            justify-content: flex-start;
        }
    }
}
.contact-section {
    background-color: #1a2331;
    width: 100%;
    &__container {
        @include touch {
            flex-direction: column;
        }
    }
    &__image-box,
    &__text-box {
        width: 50%;
        @include touch {
            width: 100%;
        }
    }
    &__image-box {
        margin-bottom: 0;
        padding-bottom: 0;
        @include touch {
            margin-bottom: 40px;
            justify-content: flex-start;
        }
        & figure {
            margin: 0;
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }
    }
    &__text-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0 50px;
        @include touch {
            padding: 0 30px 80px;
        }
        /deep/ h2 {
            color: #fff;
            font-size: 3.4rem;
            max-width: 400px;
            font-family: 'BlackerProDis-Rg';
            text-transform: capitalize;
        }
        /deep/ a,
        /deep/ p {
            color: $grey;
            font-size: 1.8rem;
        }
        /deep/ .main-link {
            color: $gold;
            &::after {
                background-color: $gold;
            }
        }
    }
}
</style>

<template>
<section class="blog">
	<div class="gold-square-left"></div>
	<div class="custom-container">
		<div class="blog__main-post"
		     v-for="(item, index) in firstPost"
		     :key="index">
			<div class="blog__main-title"
			     data-aos="fade-left"
			     data-aos-duration="1000">
				<h1>Blog</h1>
				<h2 v-html="item.title"></h2>
				<!-- <router-link v-if="lang == 'en'"
				             class="blog__btn"
				             :to="item.slug">Read More</router-link> -->
				<router-link v-if="lang == 'de'"
				             class="blog__btn"
				             :to="item.slug">Weiterlesen</router-link>
			</div>
			<figure class="blog__main-img"
			        data-aos="fade-right"
			        data-aos-duration="1000">
				<img :src="item.thumbnail"
				     :alt="item.title">
			</figure>
		</div>
	</div>
	<div class="main-container">
		<div class="blog__post"
		     v-for="(post, index) in posts"
		     :key="index">
			<div class="blog__title"
			     data-aos="fade-right"
			     data-aos-duration="1000">
				<span>{{post.date}}</span>
				<h2 v-html="post.title"></h2>
				<!-- <router-link v-if="lang == 'en'"
				             class="blog__btn"
				             :to="post.slug">Read More</router-link> -->
				<router-link v-if="lang == 'de'"
				             class="blog__btn"
				             :to="post.slug">Weiterlesen</router-link>
			</div>
			<figure class="blog__img"
			        data-aos="fade-left"
			        data-aos-duration="1000">
				<img :src="post.thumbnail"
				     :alt="post.title">
			</figure>
		</div>
	</div>
</section>
</template>

<script>
export default {
	computed: {
		posts() {
			return this.$store.getters.getPosts.slice(1, 15);
		},
		firstPost() {
			return this.$store.getters.getPosts.slice(0, 1);
		},
		postsPage() {
			return this.$store.getters.getOptionsPostsPage;
		},
		lang() {
			return this.$store.getters.getLocale;
		}
	}
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/fonts.sass";

.main-container {
    margin: 0 200px;
    @include desktop-only {
        margin: 0 150px;
    }
    @include tablet-s {
        margin: 0 100px;
    }
    @include mobile {
        margin: 0 50px;
    }
}

.blog {
    margin-top: 50px;
    &__main-post {
        display: flex;
        flex-direction: row-reverse;
        @include touch {
            flex-direction: column;
            flex-direction: column-reverse;
            margin: 0 30px;
        }
    }
    &__main-img {
        flex: 1;
        padding-right: 50px;
        @include touch {
            padding: 0;
        }
    }
    &__main-title,
    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        h1,
        span {
            color: $gray;
        }
        h1 {
            font-size: 1.6rem;
        }
        h2 {
            font-family: 'Blacker', sans-serif;
            font-size: 2.2rem;
            font-weight: bold;
            padding: 20px 0;
            color: $dark;
            text-transform: none;
            @include mobile {
                font-size: 1.8rem;
                padding: 10px 0;
            }
        }
        p {
            text-transform: uppercase;
            color: $gold;
        }
        @include tablet-s {
            h1,
            span {
                padding-top: 10px;
            }
        }
    }
    &__btn {
        width: 90px;
        text-decoration: underline;
        color: $dark;
    }
    &__post {
        margin-top: 75px;
        display: flex;
        @include tablet-xs {
            flex-direction: column-reverse;
        }
    }
    &__post:nth-child(even) {
        flex-direction: row-reverse;
        .blog__title {
            padding-left: 50px;
            @include tablet-xs {
                padding: 0;
            }
        }
        @include tablet-xs {
            flex-direction: column-reverse;
        }
    }
    &__post:nth-child(odd) {
        .blog__title {
            padding-right: 35px;
            @include tablet-xs {
                padding: 0;
            }
        }
    }

    &__post:nth-last-child(1) {
        margin-bottom: 50px;
    }

    &__img {
        flex: 1;
        img {
            object-fit: cover;
        }
    }
    &__img,
    &__main-post,
    &__title {
        z-index: 10;
    }
}
.gold-square-left {
    background-color: $beigeBg;
    height: 900px;
    width: 75%;
    position: absolute;
    bottom: -100px;
    left: 0;
    z-index: 1;
}
</style>
